import React, { useState, useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
// import eye_icon from "../../assets/icons/common/Eye.svg";
import edit_icon from "../../assets/icons/common/Edit.svg";
// import delete_icon from "../../assets/icons/common/Delete.svg";
import { PaginationControl } from "react-bootstrap-pagination-control";

import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

const HSNPage = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [btn, setBtn] = useState(false);
  const [input, setInput] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [ID, setID] = useState("");
  const [search, setSearch] = useState();
  const [uploads, setUpload] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState({
    start: 0,
    end: 100,
  });
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 33333,
  };

  useEffect(() => {
    // console.log("page-test", page);
    let start = `${page}00`;
    start = +start;
    let end = start + 100;
    setPageLimit({
      start,
      end,
    });
  }, [page]);

  const handleChage = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const handleChages = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((pre) => ({ ...pre, [name]: value }));
  };

  const modelHandler = (item) => {
    let obj = {
      hsn_code: item?.code_name,
      description: item?.description,
      sgst: item?.sgst,
      cgst: item?.cgst,
      igst: item?.igst,
    };
    setID(item?.id);
    setInputs(obj);
    setShows(true);
  };

  const submitHandler = () => {
    if (
      input?.hsn_code &&
      input?.description &&
      input?.sgst &&
      input?.cgst &&
      input?.igst
    ) {
      let formdata = new FormData();

      formdata.append("codes", input?.hsn_code);
      formdata.append("description", input?.description);
      formdata.append("sgst", input?.sgst);
      formdata.append("cgst", input?.cgst);
      formdata.append("igst", input?.igst);
      setBtn(true);
      api.addHSN(formdata).then((res) => {
        alert(res?.message);
        setInput("");
        setShow(false);
        setBtn(false);
        getList();
      });
    } else {
      alert("Please Fill All Details");
    }
  };

  const submitHandlers = () => {
    if (
      inputs?.hsn_code &&
      inputs?.description &&
      inputs?.sgst &&
      inputs?.cgst &&
      inputs?.igst
    ) {
      let formdata = new FormData();

      formdata.append("codes", inputs?.hsn_code);
      formdata.append("description", inputs?.description);
      formdata.append("sgst", inputs?.sgst);
      formdata.append("cgst", inputs?.cgst);
      formdata.append("igst", inputs?.igst);

      formdata.append("hsn_code_id", ID);
      setBtn(true);
      api.updateHSN(formdata).then((res) => {
        alert(res?.message);
        setBtn(false);
        setShows(false);
        getList();
      });
    } else {
      alert("Please Fill All Details");
    }
  };

  const getList = () => {
    let params = `?page=${page}`;
    api
      .getHSN(params)
      .then((res) => {
        setList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const searchHsn = () => {
    let data = {
      code: search,
    };
    api
      .searchHSN(data)
      .then((res) => {
        let temp = [];
        temp.push(res?.hsn_code);
        setList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const uploadFile = () => {
    let data = {
      import_excel: uploads,
    };

    // console.log("🚀 ~ file: HsnPage.js:127 ~ uploadFile ~ data:", data);
    if (uploads) {
      setLoading(true);
      api
        .hsnMaterialImport(data)
        .then((res) => {
          alert(res?.message);
          setUpload("");
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      alert("Please Upload File");
    }
  };

  useEffect(() => {
    if (search) {
      searchHsn();
    } else {
      getList();
    }
  }, [search]);

  useEffect(() => {
    getList();
  }, [page]);

  // console.log("list", list);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <CirclesWithBar
        visible={loading}
        height="110"
        width="110"
        color="#4fa94d"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={style}
      />

      <Col className="emply-master-form  px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4>HSN Details</h4>
        <div className="d-flex justify-content-end align-items-center">
          <input
            placeholder="search hsn code"
            className="border-0 bg-lightBlue px-2 py-1  mt-1 mx-3"
            style={{ height: "40px" }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <button
            type="button"
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => setShow(true)}
          >
            Add HSN
          </button>
        </div>
        <Col xs={12} className="inputinner-box text-start">
          <div className="d-flex justify-content-end my-3">
            <a
              href="http://erp-script.cresconprojects.com/public/excel/cresscon-hsn.xlsx"
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group  w-50">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                onChange={(e) => setUpload(e.target.files[0])}
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </div>
        </Col>
        <div className="user-mange-table">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>HSN Code</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.hsn_codes?.map((item, ind) => (
                <tr>
                  <td className="fs-14px">
                    {(list?.pagination?.current_page - 1) * 10 + ind + 1}
                  </td>
                  <td className="fs-14px">{item?.code_name}</td>
                  <td className="text-wrap fs-14px">{item?.description}</td>
                  <td>
                    <div className="d-flex">
                      <img
                        onClick={() => modelHandler(item)}
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={edit_icon}
                        alt="edit-icon"
                      />
                      {/* <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={edit_icon}
                        alt="eye-icon"
                      />
                      <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={delete_icon}
                        alt="eye-icon"
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add HSN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="my-2">
              <label className="w-100">HSN Code</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="HSN code"
                name="hsn_code"
                onChange={handleChage}
                value={input?.hsn_code}
              />
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">Description</label>
              <textarea
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="description"
                name="description"
                maxLength={250}
                onChange={handleChage}
                value={input?.description}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">SGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="SGST"
                name="sgst"
                onChange={handleChage}
                value={input?.sgst}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">CGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="CGST"
                name="cgst"
                onChange={handleChage}
                value={input?.cgst}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">IGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="IGST"
                name="igst"
                onChange={handleChage}
                value={input?.igst}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={shows} onHide={() => setShows(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit HSN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="my-2">
              <label className="w-100">HSN Code</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="HSN code"
                name="hsn_code"
                onChange={handleChages}
                value={inputs?.hsn_code}
              />
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">Description</label>
              <textarea
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="description"
                name="description"
                maxLength={250}
                onChange={handleChages}
                value={inputs?.description}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">SGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="SGST"
                name="sgst"
                onChange={handleChages}
                value={inputs?.sgst}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">CGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="CGST"
                name="cgst"
                onChange={handleChages}
                value={inputs?.cgst}
              />
            </Col>
            <Col sm={4} className="my-2">
              <label className="w-100">IGST</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="IGST"
                name="igst"
                onChange={handleChages}
                value={inputs?.igst}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => setShows(false)}
          >
            Close
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandlers}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <div className="my-1 py-1 d-flex justify-content-end">
        <PaginationControl
          page={page}
          between={4}
          total={list?.pagination?.total}
          limit={10}
          changePage={(page) => {
            setPage(page);
            console.log(page);
          }}
          ellipsis={1}
        />
      </div>
    </Col>
  );
};

export default HSNPage;
