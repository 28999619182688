import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import ReactDOM from "react-dom";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { renderToString } from "react-dom/server"; // Import renderToString from ReactDOM for server-side rendering
import PrinterFooter from "../../component/PoList/PrinterFooter";

function PoOutputScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [poAddress, setPoAddress] = useState([]);
  const [companyAddress, setCompanyAddress] = useState({});
  const [terms, setTerms] = useState([]);
  const [directPoAttributes, setDirectPoAttributes] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);
  const [approval, setapproval] = useState("");
  // poPrintDetails?.material_request?.request_type == 1

  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  const getList = (paramId) => {
    api
      .purchaseOrderView(paramId ? paramId : location.state?.id)
      .then((res) => {
        if (res?.status == "success") {
          setCompanyAddress(res?.company);
          setPOPrintDetails(res?.purchase_orders);
          setPoTable(res?.purchase_orders?.purchase_order_details);
          setPOAnnex(res?.purchase_orders?.purchase_order_annexures);
          let temp = [];

          const totalValue =
            res?.purchase_orders?.purchase_order_details?.reduce(
              (acc, po) => acc + +po?.net_value,
              0
            );
          setapproval(res?.purchase_orders?.approval[0]?.employee?.name);
          if (totalValue) {
            doConvert(totalValue);
          }
          if (
            res?.purchase_orders?.terms_conditions &&
            res?.purchase_orders?.terms_conditions.includes(",")
          ) {
            temp = res?.purchase_orders?.terms_conditions.split(",");
          } else {
            res?.purchase_orders?.terms_conditions &&
              temp.push(res?.purchase_orders?.terms_conditions);
          }
          setTerms(temp);
          if (res?.purchase_orders?.material_request?.request_type == 2) {
            let temp_att = [];
            let temp_obj = {};
            res?.purchase_orders?.purchase_order_details?.map((po) => {
              po?.attributes && po?.attributes.includes(",")
                ? temp_att.push(po?.attributes.split(","))
                : temp_att.push(po?.attributes);
              temp_obj[po?.id] = temp_att;
            });

            setDirectPoAttributes(temp_obj);
          }

          let po_addressArr = [];

          if (
            res?.purchase_orders?.po_address &&
            res?.purchase_orders?.po_address.includes(",")
          ) {
            po_addressArr = res?.purchase_orders?.po_address.split(",");
          } else {
            res?.purchase_orders?.po_address &&
              po_addressArr.push(res?.purchase_orders?.po_address);
          }

          setPoAddress(po_addressArr);
        }
      });
  };
  useEffect(() => {
    if (searchParams?.get("id")) {
      getList(searchParams?.get("id"));
    } else {
      getList();
    }
  }, [searchParams?.get("id")]);

  const HeaderContent = () => (
    <div className="print-header">
      <header
        id="header-content"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          textAlign: "center",
          padding: "10px 0",
          border: "1px solid #000",
          fontSize: window.innerWidth > 1366 ? "25px" : "14px", // Fixed font size in pixels
          // borderBottom: "0px solid #000", // Optional: Add a border to separate the header
        }}
      >
        <Col xs={1} className="d-flex my-3 ">
          <img
            className="ms-4"
            style={{ width: "120px", height: "120px" }}
            src={logo}
            alt="logo"
          />
        </Col>
        <Col
          xs={7}
          className="d-flex flex-column po-vendor-detail-invoice-box my-3"
        >
          <h5
            className="mx-auto py-1"
            style={{ fontSize: window.innerWidth > 1366 ? "30px" : "23px" }}
          >
            Crescon Projects and Services Pvt Ltd
          </h5>
          <p
            className="py-1 mx-auto"
            style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
          >
            Corp. Off: No. 158, 3rd Floor, Gulecha Towers, Arcot Road,
          </p>
          <p
            className="py-1 mx-auto"
            style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
          >
            Vadapalani Chennai – 600 026
          </p>
          <h6
            className="m-auto py-1"
            style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
          >
            Purchase Order
          </h6>
        </Col>
        <Col xs={4} className="m-auto" style={{ textAlign: "left" }}>
          <p
            style={{
              fontWeight: "600",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
            className="py-1"
          >
            ISO 9001:2015 : Quality Management System
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
            className="py-1"
          >
            ISO 14001:2015 : Environmental Management System
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
            className="py-1"
          >
            ISO 45001:2018 : Occupational Health & Safety Management System
          </p>
        </Col>
      </header>
      {/* <table
        className="print-header"
        style={{ width: "100%", border: "1px solid #000" }}
      >
        <thead style={{ width: "100%" }}>
          {poPrintDetails?.material_request?.request_type == 1 ? (
            <>
              <tr style={{ width: "100%" }}>
                <th style={{ padding: "30px" }}>Sl.No</th>
                <th style={{ padding: "30px" }}>
                  {poPrintDetails?.type == 1
                    ? "Assets Code"
                    : "Material Description"}{" "}
                </th>
                <th style={{ padding: "30px" }}>UOM</th>
                <th style={{ padding: "30px" }}>Quantity</th>
                <th style={{ padding: "30px" }}>Unit Rate (INR) </th>
                <th style={{ padding: "30px" }}>Total Value</th>
                <th style={{ padding: "30px" }}>HSN Code</th>
                {+poTable[0]?.igst_value < 1 && (
                  <>
                    <th>SGST</th>
                    <th>CGST</th>
                  </>
                )}

                {+poTable[0]?.igst_value > 0 && <th>IGST</th>}
                <th>Net Value</th>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <th>Sl.No</th>
                <th>
                  {poPrintDetails?.type == 1
                    ? "Assets Code"
                    : "Material Description"}{" "}
                </th>
                <th>UOM</th>
                <th>Quantity</th>
                <th>Basic Rate (INR) </th>
                <th>Total Value</th>
              </tr>
            </>
          )}
        </thead>
      </table> */}
    </div>
  );

  // Define the HTML footer content
  const FooterContent = () => (
    <div
      id="page-break"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginLeft: "0%",
        paddingBottom: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "0px",
          padding: "20px",
          border: "1px solid #000",
        }}
      >
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <p
            style={{
              textTransform: "capitalize",
              margin: 0,
              marginBottom: "10px",
              marginTop: "30px",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
          >
            {poPrintDetails?.created_user?.name}
          </p>
          <label
            style={{
              fontWeight: "600",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
          >
            Prepared
          </label>
        </div>
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <p
            style={{
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
              textTransform: "capitalize",
              margin: 0,
              marginBottom: "10px",
              marginTop: "30px",
            }}
          >
            {approval ? approval : "-"}
          </p>
          <label
            style={{
              fontWeight: "600",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
            }}
          >
            Approved
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          padding: "20px",
          borderBottom: "1px solid #000",
          borderLeft: "1px solid #000",
          borderRight: "1px solid #000",
        }}
      >
        <div
          style={{
            borderRight: "1px solid black",
            minHeight: "6rem",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p style={{ margin: 0, fontSize: "26px", fontWeight: "600" }}>
            Please Sign and Return Duplicate Copy as a Token of Acceptance
          </p>
          <p
            style={{
              marginBottom: "1rem",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
              fontWeight: "600",
              marginTop: "70px",
            }}
          >
            Vendor Acceptance
          </p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: "6rem",
            paddingLeft: "10px",
          }}
        >
          <p
            style={{
              marginLeft: "auto",
              marginRight: "2rem",
              paddingRight: "2rem",
              margin: 0,
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
              textAlign: "right",
              fontWeight: "600",
            }}
          >
            For Crescon Projects and Services Pvt Ltd
          </p>
          <p
            style={{
              marginTop: "70px",
              marginLeft: "auto",
              marginRight: "2rem",
              marginBottom: "1rem",
              fontSize: window.innerWidth > 1366 ? "25px" : "14px",
              fontWeight: "600",
            }}
          >
            Authorised Signatory
          </p>
        </div>
      </div>
    </div>
  );

  const addFooter = async (doc, footerHTML, pageHeight, pageNumber) => {
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    ReactDOM.render(
      <div>
        {footerHTML}
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: window.innerWidth > 1366 ? "25px" : "14px",
          }}
        >
          {pageNumber}
        </p>
      </div>,
      tempDiv
    );
    const canvas = await html2canvas(tempDiv, {
      scale: 2, // Adjust the scale for higher resolution and consistency
      useCORS: true, // In case you are loading images cross-origin
    });
    const imgData = canvas.toDataURL("image/png");
    const imgProps = doc.getImageProperties(imgData);
    const imgWidth = 190;
    const imgHeight = 50;

    doc.addImage(
      imgData,
      "PNG",
      10,
      pageHeight - (imgHeight - 10),
      imgWidth,
      imgHeight
    );

    ReactDOM.unmountComponentAtNode(tempDiv);
    document.body.removeChild(tempDiv);
  };

  const addHeader = async (doc, headerHTML) => {
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    ReactDOM.render(<div>{headerHTML}</div>, tempDiv);

    const canvas = await html2canvas(tempDiv, {
      scale: 2, // Adjust the scale for higher resolution and consistency
      useCORS: true, // In case you are loading images cross-origin
    });

    const imgData = canvas.toDataURL("image/png");
    const imgProps = doc.getImageProperties(imgData);
    const imgWidth = 190;
    const imgHeight = 24;

    doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight);

    ReactDOM.unmountComponentAtNode(tempDiv);
    document.body.removeChild(tempDiv);
  };

  const downloadPdfDocument = async (div_id) => {
    const divToPrint = document.getElementById(div_id);

    window.scrollTo(0, 0);
    const canvas = await html2canvas(divToPrint);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 190;
    const pageHeight = 287;
    const footerHeight = 65; // Estimated height of footer content in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    const footerHTML = <FooterContent />;
    const headerHTML = <HeaderContent />;

    let heightLeft = imgHeight;
    const doc = new JsPDF("mm");
    let position = 0;
    let pageNumber = 1;

    doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight - footerHeight);
    await addFooter(doc, footerHTML, pageHeight, pageNumber);
    await addHeader(doc, headerHTML);
    heightLeft -= pageHeight - footerHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgWidth,
        imgHeight - footerHeight
      );
      pageNumber++;
      await addFooter(doc, footerHTML, pageHeight, pageNumber);
      await addHeader(doc, headerHTML);
      heightLeft -= pageHeight - footerHeight;
    }

    doc.save("PoInvoice.pdf");
  };

  // Example usage
  // downloadPdfDocument('some_div_id');

  return (
    <Col xs={12} className="py-2 px-1 p-md-3 w-lg-90 paged">
      <Header />
      <div className="po-main-box my-3 avoid-break">
        <section className="p-3">
          <div id="po-out-main-cont">
            <header className="d-flex ">
              <Col xs={1} className="d-flex my-3 ">
                <img
                  className="ms-4"
                  style={{ width: "70px", height: "70px" }}
                  src={logo}
                  alt="logo"
                />
              </Col>
              <Col
                xs={7}
                className="d-flex flex-column po-vendor-detail-invoice-box my-3"
              >
                <h5
                  style={{
                    fontSize: window.innerWidth > 1366 ? "35px" : "25px",
                  }}
                  className="mx-auto py-1"
                >
                  Crescon Projects and Services Pvt Ltd
                </h5>
                <p
                  style={{
                    fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                  }}
                  className="py-1 mx-auto"
                >
                  Corp. Off: No. 158, 3rd Floor, Gulecha Towers, Arcot Road,
                </p>
                <p
                  style={{
                    fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                  }}
                  className="py-1 mx-auto"
                >
                  Vadapalani Chennai – 600 026
                </p>
                <h6 className="m-auto py-1">Purchase Order</h6>
              </Col>
              <Col xs={4} className="my-auto" style={{ textAlign: "left" }}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                  }}
                  className="py-1 fs-13px"
                >
                  ISO 9001:2015 : Quality Management System
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                  }}
                  className="py-1 fs-13px"
                >
                  ISO 14001:2015 : Environmental Management System
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                  }}
                  className="py-1 fs-13px"
                >
                  ISO 45001:2018 : Occupational Health & Safety Management
                  System
                </p>
              </Col>
            </header>
            <main className="">
              <section className="d-flex pt-0">
                <Col
                  xs={6}
                  className="po-vendor-detail-box py-0 px-3"
                  style={{
                    marginTop: window.innerWidth > 1366 ? "50px" : "10px",
                    paddingTop: window.innerWidth > 1366 ? "0px" : "10px",
                  }}
                >
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                    }}
                  >
                    To
                  </h6>
                  <div className="d-flex  py-1">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Vendor Name
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {poPrintDetails?.vendor?.company_name}
                      </p>
                    </Col>
                  </div>
                  <div className="d-flex  my-1 py-1">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Address
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >{`${poPrintDetails?.vendor?.door_no} , ${poPrintDetails?.vendor?.address}`}</p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        State
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {poPrintDetails?.vendor?.state}
                      </p>
                    </Col>
                  </div>
                  <div className="d-flex  py-1 my-1">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "400",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Mobile No
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {poPrintDetails?.vendor?.mobile ?? "-"}
                      </p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Email ID
                      </label>
                    </Col>

                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {poPrintDetails?.vendor?.email}
                      </p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        GST No
                      </label>
                    </Col>

                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {poPrintDetails?.vendor?.gst_no ?? "-"}
                      </p>
                    </Col>
                  </div>
                </Col>
                <Col
                  xs={6}
                  className="po-vendor-detail-invoice-box px-3"
                  style={{
                    marginTop:
                      window.innerWidth > 1700
                        ? "50px"
                        : window.innerWidth > 1366
                        ? "80px"
                        : "10px",
                  }}
                >
                  <div className="d-flex ">
                    <label
                      style={{
                        fontWeight: "600",
                        fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                      }}
                    >
                      PR No
                    </label>
                    <p
                      style={{
                        fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                      }}
                    >
                      {poPrintDetails?.po_no}
                    </p>
                  </div>
                  <div className="d-flex ">
                    <label
                      style={{
                        fontWeight: "600",
                        fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                      }}
                    >
                      PR Date
                    </label>
                    <p
                      style={{
                        fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                      }}
                    >
                      {poPrintDetails?.po_date}
                    </p>
                  </div>
                  {poPrintDetails?.type == 2 && (
                    <>
                      <div className="d-flex ">
                        <label
                          style={{
                            fontWeight: "600",
                            fontSize:
                              window.innerWidth > 1366 ? "25px" : "14px",
                          }}
                        >
                          MR No
                        </label>
                        <p
                          style={{
                            fontSize:
                              window.innerWidth > 1366 ? "25px" : "14px",
                          }}
                        >
                          {poPrintDetails?.material_request?.mr_no}
                        </p>
                      </div>
                      <div className="d-flex ">
                        <label
                          style={{
                            fontWeight: "600",
                            fontSize:
                              window.innerWidth > 1366 ? "25px" : "14px",
                          }}
                        >
                          MR Date
                        </label>
                        <p
                          style={{
                            fontSize:
                              window.innerWidth > 1366 ? "25px" : "14px",
                          }}
                        >
                          {poPrintDetails?.material_request?.mr_date}
                        </p>
                      </div>
                    </>
                  )}
                </Col>
              </section>
              <section className="d-flex">
                <Col
                  style={{ borderRight: "1px solid black" }}
                  xs={6}
                  className="po-vendor-detail-invoice-box p-3"
                >
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                    }}
                  >
                    Invoicing Address:
                  </h6>

                  <div className="d-flex flex-wrap">
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Head Office
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* Crescon Projects and Services Pvt Ltd */}
                        {companyAddress?.name}
                      </p>
                    </Col>
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Address
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* 3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani
                        Chennai – 600 026 */}
                        {/* 178/4/1, Basavana Nagar Main Road, Hoodi, Mahadevapura,
                        Bengaluru, Bengaluru Urban, Karnataka-560048 */}
                        {companyAddress?.address}
                      </p>
                    </Col>

                    <Col md={4} className="mt-4">
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Contact Person
                      </label>
                    </Col>
                    <Col md={8} className="mt-4">
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* M. Janakiraman */}
                        {companyAddress?.contact_person}
                      </p>
                    </Col>
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Contact No
                      </label>
                    </Col>
                    <Col md={8}>
                      {/* <p>044 - 23664945</p> */}
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* +91 9840045270 */}
                        {companyAddress?.contact_no}
                      </p>
                    </Col>
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        Mail id
                      </label>
                    </Col>
                    <Col md={8}>
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* purchase@cresconprojects.com */}
                        {companyAddress?.contact_email}
                      </p>
                    </Col>
                    <Col md={4}>
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        GST No
                      </label>
                    </Col>
                    <Col md={8}>
                      {/* <p>33AAECC6175F1ZT</p> */}
                      <p
                        style={{
                          fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                        }}
                      >
                        {/* 29AAECC6175F1ZI */}
                        {companyAddress?.gst_no}
                      </p>
                    </Col>
                  </div>
                </Col>
                <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                    }}
                  >
                    Delivery Address:
                  </h6>

                  {poAddress?.length > 0 ? (
                    <div>
                      {poAddress?.map((address, ind) => (
                        <p
                          style={{
                            color: "#000",
                            fontWeight: 600,
                            fontSize:
                              window.innerWidth > 1366 ? "21px" : "14px",
                          }}
                          className="py-1 page_break_before_after"
                          key={ind}
                        >
                          {address}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Project Name
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.name ?? "-"}
                          </p>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Project Address
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {`${poPrintDetails?.project?.door_no}, ${poPrintDetails?.project?.address}` ??
                              "-"}
                          </p>
                        </Col>
                      </div>

                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            District
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.district ?? "-"}
                          </p>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            State
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.state ?? "-"}
                          </p>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Pincode
                          </label>
                        </Col>

                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.pincode ?? "-"}
                          </p>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Contact No
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.mobile ?? "-"}
                          </p>
                        </Col>
                      </div>
                      <div className="d-flex">
                        <Col xs={4}>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Mail id
                          </label>
                        </Col>
                        <Col xs={8}>
                          <p
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            {poPrintDetails?.project?.email ?? "-"}
                          </p>
                        </Col>
                      </div>
                    </>
                  )}
                </Col>
              </section>
              <Col className="py-2 my-1 text-center">
                <div
                  // style={{ overflowX: "scroll" }}
                  className="po-out-table bg-white"
                >
                  <table
                    id="po-output-table"
                    className="table table-responsive-xl"
                  >
                    <thead>
                      {poPrintDetails?.material_request?.request_type == 1 ? (
                        <>
                          <tr>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Sl.No
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poPrintDetails?.type == 1
                                ? "Assets Code"
                                : "Material Description"}{" "}
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              UOM
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Unit Rate (INR){" "}
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Total Value
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              HSN Code
                            </th>
                            {+poTable[0]?.igst_value < 1 && (
                              <>
                                <th
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  SGST
                                </th>
                                <th
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  CGST
                                </th>
                              </>
                            )}

                            {+poTable[0]?.igst_value > 0 && (
                              <th
                                style={{
                                  fontSize:
                                    window.innerWidth > 1366 ? "25px" : "14px",
                                }}
                              >
                                IGST
                              </th>
                            )}
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Net Value
                            </th>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Sl.No
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poPrintDetails?.type == 1
                                ? "Assets Code"
                                : "Material Description"}{" "}
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              UOM
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Basic Rate (INR)
                            </th>
                            <th
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              Total Value
                            </th>
                          </tr>
                        </>
                      )}
                    </thead>

                    {poPrintDetails?.material_request?.request_type == 1 ? (
                      <tbody>
                        {poTable?.map((item, ind) => {
                          return (
                            <>
                              <tr
                                style={{ height: "130px" }}
                                className="newpage"
                                key={item?.id}
                              >
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {ind + 1}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  className="text-start"
                                >
                                  {item?.material_description}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.uom}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.qty}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.basic_price}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.total_value}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.hsn_code?.code_name ?? "-"}
                                </td>
                                {item?.igst_value < 1 && (
                                  <>
                                    <td
                                      style={{
                                        fontSize:
                                          window.innerWidth > 1366
                                            ? "25px"
                                            : "14px",
                                      }}
                                    >
                                      {item?.sgst_value}
                                    </td>
                                    <td
                                      style={{
                                        fontSize:
                                          window.innerWidth > 1366
                                            ? "25px"
                                            : "14px",
                                      }}
                                    >
                                      {item?.cgst_value}
                                    </td>
                                  </>
                                )}

                                {+item?.igst_value > 0 && (
                                  <td
                                    style={{
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    {item?.igst_value}
                                  </td>
                                )}
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.net_value}
                                </td>
                              </tr>
                              <tr
                                style={{ height: "90px" }}
                                className="newpage1"
                              >
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  colSpan="1"
                                >
                                  <label
                                    className="text-nowrap"
                                    style={{
                                      fontWeight: "600",
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    Specification :
                                  </label>
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  colspan="11"
                                  className="text-start"
                                >
                                  <ul>
                                    {item?.specifications?.map((item, ind) => (
                                      <li
                                        style={{
                                          fontSize:
                                            window.innerWidth > 1366
                                              ? "25px"
                                              : "14px",
                                        }}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        {poTable?.map((item, ind) => {
                          let spi = item?.material?.attribute;
                          return (
                            <>
                              <tr className="newpage" key={item?.id}>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {ind + 1}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  className="text-start"
                                >
                                  {item?.material?.name}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.uom || item?.material?.uom}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.qty}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.basic_price}
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                >
                                  {item?.net_value}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  colSpan="1"
                                >
                                  <label
                                    className="text-nowrap"
                                    style={{
                                      fontFamily: "f2",
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    Specifications :
                                  </label>
                                </td>
                                <td
                                  style={{
                                    fontSize:
                                      window.innerWidth > 1366
                                        ? "25px"
                                        : "14px",
                                  }}
                                  colspan="11"
                                  className="text-start "
                                >
                                  <ul>
                                    {item?.attributes
                                      ? item?.attributes
                                      : item?.attributes == null
                                      ? item?.specifications?.length > 0
                                        ? item?.specifications?.map(
                                            (ite, inde) => (
                                              <li key={inde}>{ite}</li>
                                            )
                                          )
                                        : `${
                                            spi?.specification1
                                              ? spi?.specification1 + " ,"
                                              : ""
                                          } ${
                                            spi?.specification2
                                              ? spi?.specification2 + " ,"
                                              : ""
                                          } ${
                                            spi?.specification3
                                              ? spi?.specification3 + " ,"
                                              : ""
                                          } ${
                                            spi?.specification4
                                              ? spi?.specification4 + " ,"
                                              : ""
                                          }
                                    ${
                                      spi?.specification5
                                        ? spi?.specification5 + " ,"
                                        : ""
                                    } ${
                                            spi?.specification6
                                              ? spi?.specification6 + " ,"
                                              : ""
                                          }`
                                      : directPoAttributes[item?.id] &&
                                        directPoAttributes[item?.id][0]?.map(
                                          (att, ind) => <li key={ind}>{att}</li>
                                        )}
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </Col>

              <div className="py-2 my-1 text-center">
                <div
                  // style={{ overflowX: "scroll" }}
                  className="po-out-table bg-white"
                >
                  <table
                    id="po-output-table"
                    className="table table-responsive-xl"
                  >
                    <thead>
                      <tr>
                        {poTable[0]?.basic_price > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Basic Rate (INR){" "}
                          </th>
                        )}
                        {poTable[0]?.discount_value > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Disc
                          </th>
                        )}
                        {poTable[0]?.cgst_value > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            CGST
                          </th>
                        )}
                        {poTable[0]?.igst_value > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            IGST
                          </th>
                        )}
                        {poTable[0]?.sgst_value > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            SGST
                          </th>
                        )}
                        {poTable[0]?.net_value > 0 && (
                          <th
                            style={{
                              fontSize:
                                window.innerWidth > 1366 ? "25px" : "14px",
                            }}
                          >
                            Total Value
                          </th>
                        )}
                      </tr>
                    </thead>
                    {poTable?.length > 0 ? (
                      <tbody>
                        <tr>
                          {poTable[0]?.basic_price > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return (
                                  Number(accumulator) +
                                  Number(object.basic_price)
                                );
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.discount_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return accumulator + object.discount_value;
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.cgst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                    Number(object.cgst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.igst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                    Number(object.igst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.sgst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                    Number(object.sgst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.net_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) + Number(object.net_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          {poTable[0]?.basic_price > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.basic_price}
                            </td>
                          )}
                          {poTable[0]?.discount_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.discount_value}
                            </td>
                          )}
                          {poTable[0]?.cgst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.cgst_value}
                            </td>
                          )}
                          {poTable[0]?.igst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.igst_value}
                            </td>
                          )}
                          {poTable[0]?.sgst_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.sgst_value}
                            </td>
                          )}
                          {poTable[0]?.net_value > 0 && (
                            <td
                              style={{
                                fontSize:
                                  window.innerWidth > 1366 ? "25px" : "14px",
                              }}
                            >
                              {poTable[0]?.net_value}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              <div className="d-flex gap-2 m-3 page_break_before_after">
                <label
                  style={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: window.innerWidth > 1366 ? "21px" : "14px",
                  }}
                >
                  Amount In Words
                </label>
                <p
                  className="mt-0"
                  style={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: window.innerWidth > 1366 ? "21px" : "14px",
                  }}
                >
                  ({numToWords ? `Rupees ${numToWords} Only` : "-"})
                </p>
              </div>
              {terms[0] != "" && (
                <section className="po-terms-box page_break_before_after">
                  <h6
                    style={{
                      fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                    }}
                  >
                    Terms And Conditions
                  </h6>
                  <div>
                    {terms?.map((term) => (
                      <p
                        style={{
                          color: "#000",
                          fontWeight: 600,
                          fontSize: window.innerWidth > 1366 ? "21px" : "14px",
                        }}
                        className="py-1 page_break_before_after"
                      >
                        {term}
                      </p>
                    ))}
                  </div>
                </section>
              )}

              <section>
                {poAnnex?.length > 0 && (
                  <div className="page_break_before_after">
                    <h6
                      style={{
                        color: "#000",
                        fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                      }}
                      className="px-2 pt-2 m-1"
                    >
                      Annexure Details
                    </h6>
                    <Col className="emply-master-form px-2  m-1 text-center">
                      <div
                        style={{ overflowX: "scroll" }}
                        className="boq-table bg-white"
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  color: "#000",
                                  fontSize:
                                    window.innerWidth > 1366 ? "25px" : "14px",
                                }}
                                className=""
                              >
                                Sl.No
                              </th>
                              <th
                                style={{
                                  color: "#000",
                                  fontSize:
                                    window.innerWidth > 1366 ? "25px" : "14px",
                                }}
                                className=""
                              >
                                Spefication and Description
                              </th>
                              <th
                                style={{
                                  color: "#000",
                                  fontSize:
                                    window.innerWidth > 1366 ? "25px" : "14px",
                                }}
                                className=""
                              >
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {poAnnex &&
                              poAnnex?.map((annex, ind) => (
                                <tr key={annex?.id}>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    {ind + 1}
                                  </td>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    {annex?.descriptions}
                                  </td>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontSize:
                                        window.innerWidth > 1366
                                          ? "25px"
                                          : "14px",
                                    }}
                                  >
                                    {annex?.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </div>
                )}
              </section>
            </main>
          </div>
          <div
            className="d-flex justify-content-between w-100 page_break_before_after"
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              padding: "10px",
            }}
          >
            <div>
              <p
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
                className="text-capitalize"
              >
                {poPrintDetails?.created_user?.name}
              </p>
              <label
                style={{
                  fontWeight: "600",
                  fontSize: window.innerWidth > 1366 ? "25px" : "14px",
                }}
              >
                Prepared
              </label>
            </div>
            <div>
              <p
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
                className="text-capitalize"
              >
                {approval ? approval : "-"}
              </p>
              <label
                style={{
                  fontWeight: "600",
                }}
              >
                Approved
              </label>
            </div>
          </div>
          <div
            id="page-break"
            className="d-flex"
            style={{
              border: "1px solid black",
              padding: "0 10px 0",
              marginBottom: "20px",
            }}
          >
            <Col
              xs={6}
              style={{
                borderRight: "1px solid black",
                minHeight: "6rem",
                paddingTop: "10px",
              }}
              className="d-flex flex-column"
            >
              <p
                className=""
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
              >
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
                className="mt-4 mb-1"
              >
                Vendor Acceptance
              </p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
                className="ms-auto me-2 pe-3"
              >
                For Crescon Projects and Services Pvt Ltd
              </p>
              <p
                style={{ fontSize: window.innerWidth > 1366 ? "25px" : "14px" }}
                className="mt-auto ms-auto me-4 mb-1"
              >
                Authorised Signatory
              </p>
            </Col>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              {poPrintDetails?.status == 2 && (
                <button
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                  onClick={() => downloadPdfDocument("po-out-main-cont")}
                >
                  Print
                </button>
              )}
            </div>
            <div className="">
              {searchParams?.get("id") ? (
                <Link
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
                  to={`/procurement/puchase-order-list?projectname=${searchParams?.get(
                    "projectname"
                  )}&project=${searchParams?.get("project")}`}
                >
                  Close
                </Link>
              ) : (
                <button
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                  onClick={() => navigate(-1)}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </Col>
  );
}

export default PoOutputScreen;
